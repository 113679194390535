import Permissions from "./permssions"

const {
  view_all_sensorboxes_assets,
  view_own_sensorboxes_assets,
  view_contractor_sensorboxes_assets,
  view_assigned_users_sensorboxes_assets,
  create_or_update_sensorboxes_assets,
  delete_sensorboxes_assets,
  view_all_users,
  view_assigned_users,
  view_parents_users,
  create_edit_users,
  delete_users,
  view_raw_data,
  view_roles,
  create_edit_delete_roles,
  view_system_status,
  view_all_inspections,
  view_created_inspections,
  view_team_created_inspections,
  create_edit_inspections,
  view_all_issue_tracker,
  create_edit_issue_tracker,
  view_only_partial_asset_sensorbox_details,
  create_edit_attachment_issue_tracker
} = Permissions 

const constants = {

    role    :   {
                    'Admin'             :   1,
                    'Contractor'        :   2,
                    'Field Technician'  :   3,
                    'Consumer'          :   4
                } as any,
        


    maxLimit : 16,

    message :   {
                    email_mandatory             :   "Email is required",
                    email_valid                 :   "Please provide a valid email",
                    invalid_password            :   "Invalid password",
                    password_field_mandatory    :   "Password is required",
                    password_field_mismatch     :   "The provided password doesn't match!",
                    password_field_validation   :   "Password must be at least 6-20 characters long, a combination of uppercase, lowercase and number are required.",
                    name_mandatory              :   "Name is required",
                    sensor_name_mandatory       :   "Sensor box name is required",
                    box_name_valid              :   "Please provide a valid sensor box name",
                    system_model_valid          :   "Please provide a valid sensor box model number",
                    name_valid                  :   "Please provide a valid name",
                    phoneNumber_mandatory       :   "Phone number is required",
                    phoneNumber_valid           :   "Please provide a valid phone number", 
                    role_mandatory              :   "Roles are required",
                    new_contractor_mandatory    :   "New contractor is required",
                    assignee_mandatory          :   "Assignee is required",
                    country_mandatory           :    "Country is required",
                    state_mandatory             :    "State is required",
                    street_mandatory            :    "Street is required",
                    city_mandatory              :    "City is required",
                    city_valid                  :    "Provide a valid city name",
                    zip_mandatory               :    "Zip code is required",
                    zip_valid                   :    "Provide a valid zip code",
                    address_mandatory           :   "Address is required",
                    address_valid               :   "Please provide a valid address",
                    password_set                :   "Password has been created successfully",
                    password_change             :   "Password has been changed successfullly",
                    start_date_mandatory        :   "Start date is required",
                    end_date_mandatory          :   "End date is required",
                    invalid_dates               :   "End date should be greater than start date",
                    report_send                 :   "Report will be generated and send to your mail.",
                    longitude_mandatory         :   "Longitude is mandatory",
                    latitude_mandatory          :   "Latitude is mandatory",
                    location_mandatory          :   "Lat/Long is mandatory",
                    capacity_value_invalid      :   "Invalid capacity value",
                    heating_value_invalid       :   "Invalid heating value",
                    name_exist                  :   "Sensor box name already exists",
                    airflow_invalid             :   "Invalid airflow value",
                    email_exist                 :   "Email id already exists",
                    comment_mandatory           :   "Comment is required",
                    max_min_value_mandatory     :   "Max and Min values are mandatory",
                    max_min_invalid_value       :   "Invalid max/min value",
                    max_must_be_greater_than_min:   "Max value must be greater than min value",
                    calibration_must_be_range   :   "Invalid calibration value. Valid range is -999.99 to 999.99 with two decimal places",
                    dateError                   :   "Graph can only be generated for a maximum of 1 month",
                    invalid_tested_date         :   "Tested date should be less than or equal to commissioned date or decommissioned date.",
                    invalid_commissioned        :   "Commissioned date should be greater than or equal to tested date and lesser than or equal to decommissioned date",
                    invalid_decommissioned      : "Decommissioned date should be greater than or equal to tested date and commissioned date",
                    cannot_be_decommissioned    :'The Sensor Box must be removed from the Asset before it can be decommissioned.',
                    group_name_mandatory        :   "Asset name is required",
                    group_name_exists           :   "Asset name already exists",
                    group_sensorboxes_min       :   "Please select at least one sensor box",
                    group_sensorboxes_max       :   "Maximum limit of 15 sensor boxes reached.",
                    group_merge_timeout_required:   "Asset merge timeout is required.",
                    group_merge_timeout_valid_range:"Merge timeout should between 1 and 60.",
                    pinConfig_display_name_required : 'Please provide a valid name',
                    pinConfig_type_required      : 'Please provide a valid type',
                    pinConfig_representation_required : 'Please provide a valid representation',
                    pinConfig_sub_type_required : 'Please provide a valid sub type',
                    pinConfig_format_type_required : 'Please provide a valid format type',
                    pinConfig_pin_type_required : 'Please provide a valid pin type',
                    organization_name_required : 'Please provide a valid organization name',
                    orgnization_name_valid      : 'Please provide a valid organization name',
                    organization_address_required :'Please provide a valid organization address',
                    organization_admin_name_required : 'Please provide a valid name',
                    organization_admin_email_required : 'Please provide an email address',
                    organization_admin_email_exists_required : 'This email address already exists',
                    organization_admins_required : 'Please provide atleast one admin details',
                    role_name_required : 'Please provide a valid role name',
                    role_position_reference_required : 'Please provide a role type',
                    role_access_permission_required : 'Atleast One access permission required',
                    error_role_organization : 'No Organizations added',
                    rv_mode_required            : 'Please select reverse valve energized mode',
                    heat_provided_by_required   : 'Heat provided by is required',
                    organization_exists         :   "Organization name already exists",
                    system_exists               :  "System name already exists for this user",
                    role_exists         :   "Role name already exists" ,
                    offline_alert_interval_required : "Offline alert interval required" ,
                    device_offline_checking_duration_required : "Device offline checking duration required",
                    offline_alert_interval_notlessthan_one : "Offline alert interval should not be less than one hour" , 
                    device_offline_checking_duration_notlessthan_thirty : "Device offline checking duration should not be less than 30 minutes" ,
                    sensorbox_category_required : "Category is required",
                    board_version_required : "Board version is required",
                    algorithm_code_required : "Diagnostic code is required",
                    algorithm_status_required : "Color status is required",
                    algorithm_refrigerent_required : "Refrigerant type  is required",
                    algorithm_description_required : "Description  is required",
                    algorithm_gate_required : "Combination gate is required",
                    algorithm_rules_required : "Please fill all fields",
                    algorithm_code_exists   :   "Diagnostic code already exists",
                    algorithm_role_type_required   :   "Algorithm rule type required",
                    test_algorithm_sensor_values_required : "Sensor values are required",
                    test_algorithm_Valid_json_required : "Provide a valid JSON",
                    device_type_required : "Device type is required",
                    contractor_required : "Contractor is required",
                    inspection_consumer_required : "Name is required",
                    inspection_email_required : "Email is required",
                    inspection_customer_required : "Customer is required",
                    inspection_systemname_required : "System name is required",
                    scWarningMessage : "For Sub-cool calculation, Refrigerant type, Liquid pressure and Liquid temperature are needed to be configured.",
                    shWarningMessage : "For Superheat calculation, Refrigerant type, Suction pressure and Suction temperature are needed to be configured.",
                    internalServerError: "Something went wrong, please try again later!",
                    errorFindingTechnician: "Error in fetching technician list",
                    errorFetchingContractorList: "Error in fetching Contractor list",
                    deleteGroupWarning: "This asset cannot be deleted because there are unresolved, pending or open issues associated with this asset! Please try again after resolving or closing the issue",
                    deleteSensorboxWarning: "This sensorbox cannot be deleted because there are unresolved or open issues associated with this sensor box! Please try again after resolving or closing the issue",
                    issue_allocation_changed: "The chosen issue has been assigned to someone else. Sorry for any inconvenience caused !",
                    issue_doesnot_exist: "Issue doesnot exist!",
                    edit_ticket_success: "Successfully Edited Ticket",
                    attachmentUploadSuccess: "Attachments added successfully!",
                    noImageError: "Please ensure to upload atleast an image!",
                    noImageOrTextError: "Both fields cannot be empty!",
                    imageMaxLimitReached: "Maximum Image Upload Limit Exceeded!",
                    uploadConfirmation: "Are you sure you want to continue uploading the attachments?",
                    fileTypeError: "You can only upload image files!",
                    imageSizeExceeded: "Image size must be less than 10 MB !",
                    unsupportedImageFormat: "Unsupported Image Format!",
                    imageAlreadyExist: "Image already exist!"
                },

    responseCode :   {
                    unAuthorized: 401
                },


    googleMapAPIKey : 'AIzaSyDCr_lLaSXfBjC8VnddgPu--2yENTRb1QU',

    sensor_status   :   {
                grey    :  -1,
                green   :   0,
                yellow  :   1,
                orange  :   2,
                red     :   3,
                darkred :   4,
                purple  :   5
    },

    Permissions:    {
      "Dashboard/Assets & Details": [
        { permission: view_all_sensorboxes_assets, desc: "View all sensorboxes/assets" },
        { permission: view_own_sensorboxes_assets, desc: "View own sensorboxes/assets" , default: true },
        { permission: view_contractor_sensorboxes_assets, desc: "View contracted sensorboxes/assets" },
        { permission: view_assigned_users_sensorboxes_assets, desc: "View assigned users's sensorboxes/assets" },
        { permission: create_or_update_sensorboxes_assets, desc: "Create/Update sensorboxes/assets" },
        { permission: delete_sensorboxes_assets, desc: "Delete sensorboxes/assets" },
        { permission: view_only_partial_asset_sensorbox_details, desc: "View only partial Asset/ Sensorbox details" }
      ],
      "Users" : [
        { permission: view_all_users, desc: "View all users" },
        { permission: view_assigned_users, desc: "View assigned users" , default: true},
        { permission: view_parents_users, desc: "View parents users"},
        { permission: create_edit_users, desc: "Create/Edit users" },
        { permission: delete_users, desc: "Delete users" },
      ],
      "Raw Data" : [
        { permission: view_raw_data, desc: "View raw data", default: true },
      ],
      "Settings": [
        { permission: view_roles, desc: "View roles", default: true },
        { permission: create_edit_delete_roles, desc: "Create/Edit/Delete roles" },
      ]
      ,
      "System Status": [
        { permission: view_system_status, desc: "View status", default: true },
      ]
      ,
      "Field Inspection": [
        { permission: view_all_inspections, desc: "View all inspections"},
        { permission: view_created_inspections, desc: "View created inspections" , default: true},
        { permission: view_team_created_inspections, desc: "View team created inspections"},
        { permission: create_edit_inspections, desc: "Create / edit inspections"},
      ]
      ,
      "Issue Tracker": [
        { permission: view_all_issue_tracker, desc: "View all issues" , default: true},
        { permission: create_edit_issue_tracker, desc: "Create / edit issues" },
      ]
      ,
      "SOAP": [
        { permission: view_raw_data, desc: "View all soap sensor data" , default: true}
      ]
  } as any,

    sensorTypeMappings :new Map([ 
        ['_TEMP_LIQ', 'Liquid temperature'],
        ['_TEMP_SUC','Suction temperature'],
        ['_PRES_LIQ', 'Liquid pressure'],
        ['_PRES_SUC', 'Suction pressure'],
        ['_SC','Sub-cool'],
        ['_SH','Superheat'],
        ['_RH_R','Return air RH'],
        ['_DB_R','Return air temperature'],
        ['_RH_S','Supply air RH'],
        ['_DB_S','Supply air temperature'],
        ['_I_COMP','Compressor current'],
        ['_BLOWER_CURRENT','Blower current'],
        ['_FAN','Fan-on indicator'],
        ['_TSTAT_W','TSTAT Heat "W"'],
        ['_TSTAT_G', 'TSTAT Fan "G"'],
        ['_TSTAT_Y','TSTAT Cool "Y"'],
        ['_TSTAT_O','TSTAT "O"'],
        ['_FSWITCH','Float switch'],
        ['_SP','Air static pressure']
    ]),

    deviceStatus: new Map([
        [1, { text: 'Initiated', color: '', desc: 'Configuration message initiated' }],
        [2, { text: 'Sent', color: '', desc: 'Configuration message sent to Pi device' }],
        [3, { text: 'Success', color: '', desc: 'Device successfully configured' }],
        [4, { text: 'Failed', color: '', desc: 'Device configuration failed' }],
        [5, { text: 'Timeout', color: '', desc: 'Configuration message timeout' }],
    ]),

    statusColor :new Map([ 
      ['-1','grey'],
      ['0','green'],
      ['1','yellow'],
      ['2','orange'],
      ['3','red'],
      ['4','darkred'],
    ]),

    statusColorForOverview :new Map([ 
      ['-1','grey'],
      ['0','success'],
      ['1','warning'],
      ['2','orange'],
      ['3','danger'],
      ['4','darkred'],
    ]),

    algorithmDescription :new Map([ 
        ['R-1','Float switch tripped or condensate overflow.'],
        ['R-2','Power disconnected.'],
        ['DR-1','System running longer to satisfy demand.'],
        ['DR-2','System is potentially low on charge.'],
        ['DR-3','System is potentially low on charge.'],
        ['DR-4','System is potentially low on charge.'],
        ['DR-5','System is potentially low on charge.'],
        ['DR-6','System is potentially low on charge.'],
        ['DR-7','System is potentially low on charge.'],
        ['DR-8','Blower not working and the indoor coil might be freezing.'],
        ['DR-9','Blower not working and the indoor coil might be freezing.'],
        ['DR-10','Blower not working and the indoor coil might be freezing.'],
        ['DR-11','Check compressor function or system is overcharged.'],
        ['DR-12','Check compressor function or system is overcharged.'],
        ['DR-13','Check compressor function or system is overcharged.'],
        ['DR-14','Check compressor function or system is overcharged.'],
        ['DR-15','Check compressor function or system is overcharged.'],
        ['DR-16','Check AC system function. Supply above 60 deg F.'],
        ['DR-17','Check AC system function. Supply above 60 deg F.'],
        ['DR-18','System running longer to satisfy demand.'],
        ['DR-19','System running longer to satisfy demand.'],
        ['DR-20','System running longer to satisfy demand.'],
        ['DR-21','Check furnace function. Supply less than 65 deg F.'],
        ['DR-22','Check heat pump system function. Supply less than 65 deg F.'],
        ['DR-23','Check heat pump system function. Supply less than 65 deg F.'],
        ['O-1','Check blower operation.'],
        ['O-2','Check blower operation.'],
        ['O-3','Check blower operation.'],
        ['O-4','Check blower operation.'],
        ['O-5','Check blower operation.'],
        ['O-6','Check blower operation.'],
        ['O-7','Check blower operation.'],
        ['Y-1','Check or replace filter.'],
        ['Y-2','Check or replace filter.'],
        ['Y-3','Check or replace filter.'],
        ['Y-4','Check or replace filter.'],
        ['Y-5','Check or replace filter.'],
        ['Y-6','Check or replace filter.']
    ]),
    
    smallChillerAlgorithmDescription :new Map([ 
      ['R-1','Compressor losing one or more phases'],
      ['R-3','Pump overheating'],
      ['R-4','Pump non-operational'],
      ['R-6','High head pressure'],
      ['R-7','Low charge condition'],
      ['R-14','Low water pressure'],
      ['R-15','Water temperature too low.'],
      ['R-16','City water is being used.'],
      ['R-17','Power to the device is off.'],
    ]),

    chromaloxAlgorithmDescription :new Map([ 
      ['R-1','Contactor legs are not equal'],
      ['R-2','Contactor not engaging'],
      ['R-3','Contactor not engaging '],
      ['R-4','Operating pressure switch 1 stuck open.'],
      ['R-5','Low water level or Low tank switch stuck open'],
      ['R-6','High pressure switch stuck open.'],
      ['R-7','Float switch making contact on both circuits.'],
      ['R-8','Contactor stuck closed-element side.'],
      ['R-9','Contactor stuck closed-element side.']
    ]),

    statusDescription :new Map([ 
      [-1,'Only trend data available'],
      [0,'All good'],
      [1,'Need attention'],
      [2,'Need attention'],
      [3,'Action required immediately'],
      [4,'Action required immediately']
    ]),

    generatorAlgorithmDescription :new Map([
        ['R-1','Generator engaged and facility has power.'],
        ['R-2','Generator engaged, but facility is off. Likely to be panel issue.'],
        ['R-3','Generator is not engaged and facility is off'],
        ['G',`System seems to be back to normal.`],
    ]),

    addAssigneeField: 'addTechnician',
    assigneeFieldPlaceholder: 'Select any technician to assign the ticket',
    addNewTechnician: 'Add New Technician',
    addNewContractor: 'Add New Contractor',
    contractorFieldPlaceholder: 'Select any Contractor to assign the ticket',
    navigateThroughImages: "Please utilize the arrow keys to navigate through uploaded attachments.",

    defaultCurrentImage : 1,
    attachmentNotesLength: 500,
    overallSummaryLength: 500

}

export default constants