
const Routes = {

    home            :   '/',
    services        :   '/services',
    dashboard       :   '/dashboard',
    systems         :   '/systems',
    systemsdetails  :   '/details',
    devicestatus    :   '/devicestatus',
    issuetracker    :   '/issuetracker',
    reportGenerator :   '/reportGenerator',
    issuetrackerdetails:   '/issuetracker-details',
    inspectionchecklist : '/inspections',
    inspectionform  : '/inspection-form',
    users           :   '/users',
    sensordata      :   '/sensordata',
    contactus       :   '/contactus',
    login           :   '/login',
    password        :   '/password/:token',
    reminderalert   :   '/set-offline-status-reminder/:token',
    e2sensordata    :   '/e2sensordata',
    sensorrawdata   :   '/sensorrawdata',
    settings        :   '/settings',
    groups          :   '/groups',
    pinconfig       :   '/settings/pinconfig',
    systemconfig    :   '/settings/systemconfig',
    organization    :   '/settings/organization',
    role            :   '/settings/role',
    softsavings     :   '/settings/softsavings',
    algorithmconfig :   '/settings/algorithm-config',
    rulegenerator   :   '/settings/rulegenerator',    
    termsservice    :   '/terms',
    notFound        :   '/404'
}

export default Routes
  