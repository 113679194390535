import React from 'react'
import {Switch, Redirect, Route} from 'react-router-dom';
import Routes from '../../pages/Routes'
import authStore from '../../store/authStore';
import PinConfiguration from "./pin-config/PinConfiguration";
import systemConfig from "./system-config/SystemConfig"
import SideBar from "./side-bar/SideBar"
import Organization from "./organisation/Organisation"
import AlgorithmConfig from './algorithm-config/AlgorithmConfig';
import Role from "./role/Role"
import './settings.css';

function Settings() {
    return (
      <div className="mt-4 mb-25 px-2 d-flex justify-content-between">
        <div className="col-12">
          <div className="settings-header pb-4">
                  <h3>Settings</h3>
          </div>
            <div className="row">
              <SideBar /> 
                <div className="col-10">
                  <Switch>        
                    {authStore?.currentUser?.access?.includes(1) &&
                    <Route path={Routes.systemconfig} component={systemConfig}/>
                    }
                    {authStore?.currentUser?.access?.includes(1) &&
                    <Route path={Routes.pinconfig} component={PinConfiguration}/>
                    }
                    {authStore?.currentUser?.access?.includes(1) &&
                    <Route path={Routes.organization} component={Organization}/>
                    }
                    {(authStore?.currentUser?.access?.includes(2) || authStore?.currentUser?.access?.includes(7)) &&
                    <Route path={Routes.role} component={Role}/>
                    }
                    {authStore?.currentUser?.access?.includes(1) &&
                    <Route path={Routes.algorithmconfig} component={AlgorithmConfig}/>
                    }
                    {authStore?.currentUser?.access?.includes(1) &&
                    <Redirect to={Routes.systemconfig}/>  
                    }
                    {(authStore?.currentUser?.access?.includes(2) || authStore?.currentUser?.access?.includes(7)) &&
                    <Redirect to={Routes.role}/>  
                    }
                    {authStore?.currentUser?.access?.includes(1) &&
                    <Redirect to={Routes.algorithmconfig}/>  
                    }
                    {authStore?.currentUser?.access?.includes(1) &&
                    <Redirect to={Routes.rulegenerator}/>
                    }		    
                  </Switch>
                </div>
            </div>
        </div>     
      </div>
    )
}

export default Settings


