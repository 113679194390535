import React from 'react';

const RuleGenerator = () => {
  return (
    <div>
      <iframe
        title="Rule Generator"
	  src={`${process.env.PUBLIC_URL}/rulegen.html`}
          style={{
	      width:'100vw',
              height:'100vh',
	      border:'none',
	  }}
      />
    </div>
  );
};

export default RuleGenerator;
